body {
  font-family: 'Open Sans', sans-serif;
  background-color: white;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  /* Use a heavier weight for headings */
}

p,
a,
span,
input,
button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  /* Default weight for body text */
}

/* Apply to all elements */
* {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #0275d8 #daebf9;
  /* For Firefox */
}

/* For WebKit-based browsers (Chrome, Edge, Safari) */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0275d8;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #daebf9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bg-gradiant-home {
  background: linear-gradient(#0275D8, white)
}

.bg-gradiant-home-reverse {
  background: linear-gradient(white, #0275D8)
}

.mouse {
  width: 30px;
  height: 60px;
  border: 3px solid #0275D8;
  border-radius: 60px;
  position: relative;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 40px;
  }
}

/* -------------------------scroll---------------------------- */
.scroll {
  width: 60px;
  height: 60px;
  border: 4px solid #0275D8;
  /* Thicker border */
  border-radius: 50%;
  position: relative;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;

  &::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 17px;
    width: 18px;
    height: 18px;
    border-left: 4px solid #0275D8;
    /* Thicker arrow */
    border-bottom: 4px solid #0275D8;
    /* Thicker arrow */
    transform: rotate(-45deg);
  }
}

@keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}


.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  background-color: #0275D8;
  border-radius: 20%;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 10s ease-in forwards infinite;
  animation: cube 10s ease-in forwards infinite;
}

.cube:nth-child(2n) {
  border-color: #0275D8;
}

.cube:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  border-radius: 40%;
  left: 25vw;
  top: 40vh;
}

.cube:nth-child(3) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}

.cube:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}

.cube:nth-child(5) {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}

.cube:nth-child(6) {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }

  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }

  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text_dark {
  color: black
}

.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #0275d8 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0275d8 !important;
}

.top-bottom-overflow-fade {
  mask-image: linear-gradient(transparent, black 20%, black 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(transparent, black 20%, black 80%, transparent 100%);
}

.navbar-home {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-home.visible-home {
  opacity: 1;
  transform: translateY(0);
}

.menu-ham {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.teams-div {
  transition: all 0.3s ease;
}

.teams-div.collapsed {
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.teams-div.visible {
  opacity: 1;
}

/* Drop overlay for drag and drop */
.file-drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
  z-index: 10;
}

.file-drop-message {
  padding: 20px;
  border: 2px dashed #fff;
  border-radius: 10px;
}


/* -----------------------AOS AUTH ANUMATION--------------------------------- */

.box {
  position: absolute;
  transition: all 0.5s ease;
}

.hidden {
  display: none;
}

.fade-in-right-to-left {
  animation: fadeInRightToLeft 0.8s forwards;
}

.fade-out-right-to-left {
  animation: fadeOutRightToLeft 0.8s forwards;
}

@keyframes fadeInRightToLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRightToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.toggle-btn {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


/* Floating shapes */
.floating-shape {
  position: absolute;
  background-color: #0275D8;
  /* Light transparent shapes */
  border-radius: 50%;
  /* Circular shapes, change to other values for different shapes */
  animation: float 6s infinite ease-in-out;
}

.shape1 {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  width: 50vh;
  height: 50vh;
  top: 10%;
  left: 0%;
}

.shape2 {
  width: 20vh;
  height: 20vh;
  top: 30%;
  right: 0%;
}

.shape3 {
  width: 10vh;
  height: 10vh;
  bottom: 0%;
  left: 35%;
}

/* Floating animation */
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Centered video */
.centered-video {
  max-width: 75%;
  /* Ensure video stays within the container */
  max-height: 75%;
  /* Keep video responsive */
  border-radius: 10%;
  /* Optional: add some rounding to the video edges */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  /* Optional: add a soft shadow */
}

.dash-link {
  position: relative;
  display: inline-block;
}

.dash-link .arrow-icon {
  opacity: 0;
  transform: translateX(-5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dash-link:hover .arrow-icon,
.dash-link .active-arrow {
  opacity: 1;
  transform: translateX(0);
}

.dash-div {
  z-index: 10;
}

.remote-cursor {
  border-left: 2px solid red;
  animation: blink 1s steps(2, start) infinite;
}

.remote-selection {
  background-color: rgba(255, 0, 0, 0.2);
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.tips-fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.tips-fade-out {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.lander-bg-gradient {
  background: linear-gradient(to bottom, #0275d8, #daebf9);
}

.lander-text-primary {
  color: #0275d8 !important;
}

.lander-card {
  border: none;
  border-radius: 10px;
}

.lander-card-body {
  background-color: #f8f9fa;
}

footer {
  font-size: 0.9rem;
}

.img-animate {
  transition: opacity 0.5s ease-in-out;
}

/* Custom link hover effect */
.custom-link {
  position: relative;
  text-decoration: none;
  /* Prevent underline by default */
}

.custom-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0%;
  background-color: #007bff;
  /* Underline color */
  transition: width 0.3s ease;
}

.custom-link:hover::after {
  width: 100%;
  /* Full-width underline on hover */
}

.custom-link.active::after {
  width: 100%;
  /* Full-width underline for active link */
}

.terminal {
  background: #0d1117;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  padding: 0.5rem;
}

.prompt {
  color: #16c60c;
}

.command {
  color: #58a6ff;
  font-weight: bold;
}

.description {
  margin-top: 1rem;
}

.highlight {
  color: #ff7b72;
}

.output {
  color: #39d353;
  margin-left: 2rem;
}

.footer {
  margin-top: 3rem;
  text-align: center;
}

.footer {
  color: #58a6ff;
  text-decoration: none;
}

.ql-syntax {
  background-color: #ffffff !important; /* White background */
  color: #333333 !important;          /* Dark text color */
  border: 1px solid #dddddd;          /* Optional border */
  padding: 8px;
  font-family: "Courier New", Courier, monospace; /* Code font */
  overflow-x: auto;
}